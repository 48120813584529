<ng-container *ngIf="loadername=='menu-head-business-details'">
	<div class="menu-left-menucategory">
		<div class="loading-skeleton">
			<div class="col">
				<div class="box1">
					<div class="skeleton skeleton-top w75">

						<div class="skeleton-left flex1">
							<div class="square h130"></div>
						</div>


						<div class="skeleton-right  flex2 ">
							<div class="line h17 m10"></div>
							<div class="line w40"></div>
							<div class="line h8 w30"></div>
							<div class="line  w25"></div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</ng-container>
<ng-container *ngIf="loadername=='menu-left-menucategory'">
	<div class=" d-flex">
		<div class="skeleton block col-lg-3">

			<div class="skeleton-right box flex2">
				<div class="line"></div>
				<div class="line"></div>
				<div class="line"></div>
				<div class="line"></div>
				<div class="line"></div>
				<div class="line"></div>
				<div class="line"></div>
				<div class="line"></div>
			</div>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="loadername=='menu-right-cartlist'">
	<div class="skeleton block col-lg-4">
		<div class="square1 m10"></div>
		<div class="line"></div>
		<div class="line"></div>
		<div class="skeleton-right ">
			<div class="row">
				<div class="col-lg-6">
					<div class="square1 m10"></div>
				</div>
				<div class="col-lg-6">
					<div class="square1 m10"></div>
				</div>

			</div>
		</div>
		<div class="line"></div>
		<div class="line"></div>
		<div class="line"></div>
	</div>
</ng-container>
<ng-container *ngIf="loadername=='menu-center-menu-tab'">
	<div class="skeleton block col-lg-5 mb-3">
		<div class="skeleton-right ">
			<div class="row">
				<div class="col-lg-3">
					<div class="square1 m10"></div>
				</div>
				<div class="col-lg-3">
					<div class="square1 m10"></div>
				</div>
				<div class="col-lg-3">
					<div class="square1 m10"></div>
				</div>
				<div class="col-lg-3">
					<div class="square1 m10"></div>
				</div>
			</div>
		</div>
	</div>
</ng-container>


<ng-container *ngIf="loadername=='menu-center-menu-list'">
	<div class="skeleton block col-lg-5">

		<div class="square1 m10"></div>
		<div class="square1 m10"></div>

		<div class="line"></div>
		<div class="line"></div>
		<div class="line"></div>
	</div>
</ng-container>

<!-- listling-loader -->
<ng-container *ngIf="loadername=='listing-details-business-list'">
	<div class="skeleton block col-lg-5 listing-1">

		<div class="skeleton-right ">
			<div class="row">
				<div class="w35">
					<div class="square1 h145"></div>

				</div>
				<div class="w44">
					<div class="line w58 h30"></div>
					<div class="line w30 w17 "></div>
		<div class="line w21"></div>
		<div class="line line w58"></div>
		<div class="line w58"></div>
		
				</div>
				<div class="w21 mt-4">
					<div class="square1 h30"></div>
					<div class="square1 h30 mt-2"></div>
				</div>

			</div>
		</div>
	</div>
	<div class="skeleton block col-lg-5 mt-3">

		<div class="skeleton-right ">
			<div class="row">
				<div class="w35">
					<div class="square1 h145"></div>

				</div>
				<div class="w44">
					<div class="line w58 h30"></div>
					<div class="line w30 w17 "></div>
		<div class="line w21"></div>
		<div class="line line w58"></div>
		<div class="line w58"></div>
		
				</div>
				<div class="w21 mt-4">
					<div class="square1 h30"></div>
					<div class="square1 h30 mt-2"></div>
				</div>

			</div>
		</div>
	</div>
	<div class="skeleton block col-lg-5 mt-3">

		<div class="skeleton-right ">
			<div class="row">
				<div class="w35">
					<div class="square1 h145"></div>

				</div>
				<div class="w44">
					<div class="line w58 h30"></div>
					<div class="line w30 w17 "></div>
		<div class="line w21"></div>
		<div class="line line w58"></div>
		<div class="line w58"></div>
		
				</div>
				<div class="w21 mt-4">
					<div class="square1 h30"></div>
					<div class="square1 h30 mt-2"></div>
				</div>

			</div>
		</div>
	</div>
	<div class="skeleton block col-lg-5 mt-3">

		<div class="skeleton-right ">
			<div class="row">
				<div class="w35">
					<div class="square1 h145"></div>

				</div>
				<div class="w44">
					<div class="line w58 h30"></div>
					<div class="line w30 w17 "></div>
		<div class="line w21"></div>
		<div class="line line w58"></div>
		<div class="line w58"></div>
		
				</div>
				<div class="w21 mt-4">
					<div class="square1 h30"></div>
					<div class="square1 h30 mt-2"></div>
				</div>

			</div>
		</div>
	</div>
</ng-container>



<ng-container *ngIf="loadername=='listing-details-business-search'">
	<div class="skeleton block col-lg-5 mb-3">

		<div class="square1 m10"></div>
		
	</div>
</ng-container>


<ng-container *ngIf="loadername=='listing-details-business-search'">
	<div class="skeleton block col-lg-5 ">

		<div class="skeleton-right ">
			<div class="row">
				<div class="w21">
					<div class="square1 h17"></div>
				</div>
				<div class="w78">
					<div class="line"></div>
					
				</div>

			</div>
		</div>

		
	</div>
</ng-container>

<ng-container *ngIf="loadername=='listing-details-business-filter'">
	<div class="skeleton block col-lg-3 mb-3">
		<div class="skeleton-right ">
			<div class="row">
				<div class="w21">
					<div class="square1 h30"></div>
				</div>
				<div class="w78">
					<div class="line"></div>
					<div class="line"></div>
				</div>

			</div>
		</div>
		
	</div>
</ng-container>
<ng-container *ngIf="loadername=='listing-details-business-filter'">
	<div class="skeleton block col-lg-3 mb-3">
		<div class="skeleton-right ">
			<div class="row">
				<div class="w21">
					<div class="square1 h30"></div>
				</div>
				<div class="w21">
					<div class="line h30"></div>
				</div>
				<div class="w58">
					<div class="square1 h30"></div>
				</div>

			</div>
		</div>
		
	</div>
</ng-container>
<ng-container *ngIf="loadername=='listing-details-business-filter'">
	<div class="skeleton block col-lg-3">

		<div class="line h30"></div>
		<div class="line h30"></div>
		<div class="line h30"></div>
		<div class="line h30"></div>
		<div class="line h30"></div>
		<div class="line h30"></div>
		<div class="line h30"></div>
		<div class="line h30"></div>
		<div class="line h30"></div>
		<div class="line h30"></div>
		<div class="line h30"></div>
		<div class="line h30"></div>
		<div class="line h30"></div>
		<div class="line h30"></div>
		

		
	</div>
</ng-container>

<!-- listling-loader -->
 <!-- order-complete -->

 <ng-container *ngIf="loadername=='listing-details-booking-complete'">
	
	<div class="skeleton block col-lg-3">
		<div class="w40 m-auto">
			<div class="square1 h30"></div>
		</div>
		<div class="line w58 m-auto  mt-3"></div>
		
			<div class="square circle m-auto mt-3 mb-3"></div>
		
		<div class="line w21 m-auto"></div>
		

		
	</div>


 </ng-container>
 <div class="booking-complete-loader">
 <ng-container *ngIf="loadername=='listing-details-booking-complete'">
	<div class="skeleton block col-lg-3 mb-3 mt-3">
		<div class="skeleton-right ">
			<div class="row">
				<div class="w21">
					<div class="square1 h130"></div>
				</div>
				<div class="w50">
					<div class="line h30 "></div>
				</div>
				<div class="w29">
					<div class="line"></div>
					<div class="line w40"></div>
					<div class="line w30"></div>
					<div class="line w20"></div>
				</div>

			</div>
		</div>
		
	</div>
</ng-container>

<ng-container *ngIf="loadername=='listing-details-booking-complete'">
	
	
	<div class="container order-complete">
	<div class="row">
		<div class="col-lg-6">
	  <div class="skeleton order-complete ">
			  <div class="skeleton-left ">
				
				<div class="w-100">
					<div class="square1 h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>

			<div class="skeleton-left">
				
				<div class="w-100">
					<div class="square1 color h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>

			<div class="skeleton-left ">
				
				<div class="w-100">
					<div class="square1 h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>

			<div class="skeleton-left">
				
				<div class="w-100">
					<div class="square1 color h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>
			 
		</div>
		
	  </div>
	  <div class="col-lg-6">
		<div class="skeleton order-complete ">
			<div class="skeleton-left ">
				
				<div class="w-100">
					<div class="square1 h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>

			<div class="skeleton-left">
				
				<div class="w-100">
					<div class="square1 color h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>





			<div class="skeleton-left ">
				
				<div class="w-100">
					<div class="square1 h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>

			<div class="skeleton-left">
				
				<div class="w-100">
					<div class="square1 color h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>






		</div>

		
	  </div>
	 </div>
	
	

	
	
	
	</div>

</ng-container>
</div>



  <!-- order-complete -->

  <ng-container *ngIf="loadername=='listing-details-booking'">


	<div class="skeleton block col-lg-3">
		<div class="w40 m-auto">
			<div class="square1 h30"></div>
		</div>
		<div class="line w58 m-auto  mt-3"></div>
		
			<div class="square circle m-auto mt-3 mb-3"></div>
		
		<div class="line w21 m-auto"></div>
		

		
	</div>
	



  </ng-container>
  <ng-container *ngIf="loadername=='listing-details-booking'">
	<div class="skeleton block col-lg-3 mb-3 mt-3">
		<div class="skeleton-right ">
			<div class="row">
				<div class="w21">
					<div class="square1 h130"></div>
				</div>
				<div class="w50">
					<div class="line h30 "></div>
				</div>
				<div class="w29">
					<div class="line"></div>
					<div class="line w40"></div>
					<div class="line w30"></div>
					<div class="line w20"></div>
				</div>

			</div>
		</div>
		
	</div>
</ng-container>

<ng-container *ngIf="loadername=='listing-details-booking'">
	
	
	<div class="container order-complete">
	<div class="row">
		<div class="col-lg-6">
	  <div class="skeleton order-complete ">
			  <div class="skeleton-left ">
				
				<div class="w-100">
					<div class="square1 h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>

			<div class="skeleton-left">
				
				<div class="w-100">
					<div class="square1 color h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>

			<div class="skeleton-left ">
				
				<div class="w-100">
					<div class="square1 h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>

			<div class="skeleton-left">
				
				<div class="w-100">
					<div class="square1 color h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>
			 
		</div>
		
	  </div>
	  <div class="col-lg-6">
		<div class="skeleton order-complete ">
			<div class="skeleton-left ">
				
				<div class="w-100">
					<div class="square1 h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>

			<div class="skeleton-left">
				
				<div class="w-100">
					<div class="square1 color h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>





			<div class="skeleton-left ">
				
				<div class="w-100">
					<div class="square1 h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>

			<div class="skeleton-left">
				
				<div class="w-100">
					<div class="square1 color h43 d-flex">
                     <div class="line w30 mr-20 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					 <div class="line w30 mt-3"></div>
					
					</div>
				</div>

				
				
				
			</div>






		</div>

		
	  </div>
	 </div>
	
	

	
	
	
	</div>

</ng-container>



  <!-- order-complete1 -->
  


  <!-- order-complete1 -->


